import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Paper,
  IconButton,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";

const ResetPasswordForm = () => {
  const { token } = useParams(); // Obtener el token de la URL
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Las contraseñas no coinciden");
      return;
    }

    try {
      setLoading(true);
      await axiosInstance.post(`/auth/reset-password`, {
        token,
        password,
      });

      toast.success("Contraseña actualizada exitosamente");
      setTimeout(() => {
        navigate("/"); // Redirigir al login después del cambio de contraseña
      }, [2000]);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error al actualizar la contraseña");
      console.error("Error al cambiar contraseña:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <ToastContainer />
      <Box sx={{ my: 4, textAlign: "center" }}>
        <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, position: "relative" }}>
          <IconButton
            size="small"
            onClick={() => navigate("/")} // Vuelve a la página de inicio
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h4" gutterBottom>
            Cambiar Contraseña
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              label="Nueva Contraseña"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Confirmar Nueva Contraseña"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              fullWidth
              margin="normal"
              required
            />

            <Box mt={3} textAlign="center">
              <Button type="submit" variant="contained" color="primary" disabled={loading}>
                {loading ? <CircularProgress size={24} /> : "Actualizar Contraseña"}
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    </Container>
  );
};

export default ResetPasswordForm;
