import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Input,
  Grid,
  Box,
} from "@mui/material";
import { Edit, Delete, Add } from "@mui/icons-material";
import axiosInstance from "../../axiosInstance";
const baseUrl = process.env.REACT_APP_BASE_URL;

const Comprobantes = () => {
  const [comprobantes, setComprobantes] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false); // Nuevo estado para el modal de edición
  const [selectedComprobante, setSelectedComprobante] = useState(null);
  const [newComprobante, setNewComprobante] = useState({ foto: "", descripcion: "" });
  const [editComprobante, setEditComprobante] = useState({ id: "", foto: "", descripcion: "" }); // Nuevo estado para editar
  const [file, setFile] = useState(null);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    fetchComprobantes();
  }, []);

  const fetchComprobantes = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/comprobantes/prop`);
      setComprobantes(response.data);
    } catch (error) {
      console.error("Error fetching comprobantes:", error);
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenImageDialog(true);
  };

  const handleImageDialogClose = () => {
    setOpenImageDialog(false);
    setSelectedImage("");
  };

  const handleDeleteClick = (comprobante) => {
    setSelectedComprobante(comprobante);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    if (!selectedComprobante) return;
    try {
      await axiosInstance.delete(`${baseUrl}/comprobantes/${selectedComprobante.id}`);
      setOpenDeleteDialog(false);
      setSelectedComprobante(null);
      fetchComprobantes();
    } catch (error) {
      console.error("Error deleting comprobante:", error);
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
    setSelectedComprobante(null);
  };

  const handleEditClick = (comprobante) => {
    setEditComprobante(comprobante);
    setOpenEditDialog(true); // Abrir el modal de edición
  };

  const handleEditChange = (e) => {
    setEditComprobante({ ...editComprobante, [e.target.name]: e.target.value });
  };

  const handleEditFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleEditConfirm = async () => {
    try {
      const formData = new FormData();
      formData.append("foto", file);
      formData.append("descripcion", editComprobante.descripcion);

      await axiosInstance.put(`${baseUrl}/comprobantes/${editComprobante.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setOpenEditDialog(false);
      setEditComprobante({ id: "", foto: "", descripcion: "" });
      setFile(null);
      fetchComprobantes();
    } catch (error) {
      console.error("Error updating comprobante:", error);
    }
  };

  const handleEditCancel = () => {
    setOpenEditDialog(false);
    setEditComprobante({ id: "", foto: "", descripcion: "" });
    setFile(null);
  };

  const handleCreateClick = () => {
    setOpenCreateDialog(true);
  };

  const handleCreateChange = (e) => {
    setNewComprobante({ ...newComprobante, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleCreateConfirm = async () => {
    try {
      const formData = new FormData();
      formData.append("foto", file);
      formData.append("descripcion", newComprobante.descripcion);

      await axiosInstance.post(`${baseUrl}/comprobantes`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setOpenCreateDialog(false);
      setNewComprobante({ foto: "", descripcion: "" });
      setFile(null);
      fetchComprobantes();
    } catch (error) {
      console.error("Error creating comprobante:", error);
    }
  };

  const handleCreateCancel = () => {
    setOpenCreateDialog(false);
    setNewComprobante({ foto: "", descripcion: "" });
    setFile(null);
  };

  return (
    <>
      <Box sx={{ padding: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h4">Comprobantes</Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={handleCreateClick}
          >
            Agregar Comprobante
          </Button>
        </Box>

        <Grid container spacing={3} style={{ marginTop: "20px" }}>
          {comprobantes.map((comprobante) => (
            <Grid item xs={12} sm={6} md={4} key={comprobante.id}>
              <Card sx={{ maxWidth: 345, minWidth: 275 }}>
                <CardMedia
                  component="img"
                  height="140"
                  image={`${baseUrl}/${comprobante.foto}`}
                  alt="Comprobante"
                  onClick={() => handleImageClick(`${baseUrl}/${comprobante.foto}`)} // Añadir onClick aquí
                  style={{ cursor: "pointer" }} // Añadir un cursor de puntero
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {comprobante.descripcion}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Propietario: {comprobante.User.username}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Fecha: {new Date(comprobante.createdAt).toLocaleString()}
                  </Typography>
                </CardContent>
                <CardActions>
                  <IconButton onClick={() => handleEditClick(comprobante)} color="primary">
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteClick(comprobante)} color="secondary">
                    <Delete />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Diálogo de confirmación de eliminación */}
        <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
          <DialogTitle>Eliminar Comprobante</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Estás seguro de que deseas eliminar este comprobante?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleDeleteConfirm} color="secondary">
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Diálogo de creación de comprobante */}
        <Dialog open={openCreateDialog} onClose={handleCreateCancel}>
          <DialogTitle>Crear Nuevo Comprobante</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Descripción"
              name="descripcion"
              type="text"
              fullWidth
              value={newComprobante.descripcion}
              onChange={handleCreateChange}
            />
            <Input margin="dense" type="file" fullWidth onChange={handleFileChange} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCreateCancel} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleCreateConfirm} color="primary">
              Crear
            </Button>
          </DialogActions>
        </Dialog>

        {/* Diálogo de edición de comprobante */}
        <Dialog open={openEditDialog} onClose={handleEditCancel}>
          <DialogTitle>Editar Comprobante</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Descripción"
              name="descripcion"
              type="text"
              fullWidth
              value={editComprobante.descripcion}
              onChange={handleEditChange}
            />
            <Input margin="dense" type="file" fullWidth onChange={handleEditFileChange} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditCancel} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleEditConfirm} color="primary">
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
        {/* Diálogo de expansión de imagen */}
        <Dialog open={openImageDialog} onClose={handleImageDialogClose} maxWidth="md">
          <DialogContent>
            <img
              src={selectedImage}
              alt="Comprobante Expandido"
              style={{ width: "100%", height: "auto" }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleImageDialogClose} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default Comprobantes;
