import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../../axiosInstance.js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Box,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import CopyIcon from "@mui/icons-material/FileCopy";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserContext from "../../UserContext";
const baseUrl = process.env.REACT_APP_BASE_URL;

const Propietario = () => {
  const { setUser, user } = useContext(UserContext);

  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [currentUser, setCurrentUser] = useState({
    username: "",
    password: "",
    email: "",
    cedula: "", // Añadir este estado para manejar la cédula
    telefono: "", // Añadir este estado para manejar la cédula
    foto: null,
  });
  const [userToDelete, setUserToDelete] = useState(null);
  const [areas, setAreas] = useState([]);
  const [userAreas, setUserAreas] = useState([]);
  const [manageAreasOpen, setManageAreasOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [linkOpen, setLinkOpen] = useState(false); // Controla la apertura del modal
  const [generatedLink, setGeneratedLink] = useState(""); // Almacena el enlace generado
  const [loading, setLoading] = useState(false); // Controla el estado de carga para la solicitud

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser !== null && typeof storedUser !== "object") {
      setUser(JSON.parse(storedUser));
    }
  }, [setUser]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const response = await axiosInstance.get(`${baseUrl}/auth/getAllPropietarios`);
    setUsers(response.data);
  };

  const handleOpen = () => {
    setCurrentUser({
      username: "",
      password: "",
      email: "",
      cedula: "", // Añadir este estado para manejar la cédula
      telefono: "", // Añadir este estado para manejar la cédula
      foto: null,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!currentUser.username) {
      newErrors.username = "El nombre de usuario es obligatorio";
    }

    if (!currentUser.password) {
      newErrors.password = "La contraseña es obligatoria";
    } else if (currentUser.password.length < 6) {
      newErrors.password = "La contraseña debe tener al menos 6 caracteres";
    }

    if (!currentUser.email) {
      newErrors.email = "El correo electrónico es obligatorio";
    } else if (!/\S+@\S+\.\S+/.test(currentUser.email)) {
      newErrors.email = "El correo electrónico no es válido";
    }

    if (!currentUser.cedula) {
      newErrors.cedula = "La cédula es obligatoria";
    } else if (!/^\d+$/.test(currentUser.cedula)) {
      newErrors.cedula = "La cédula debe contener solo números";
    }

    if (!currentUser.telefono) {
      newErrors.telefono = "El teléfono es obligatorio";
    } else if (!/^\d+$/.test(currentUser.telefono)) {
      newErrors.telefono = "El teléfono debe contener solo números";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length !== 0;
  };

  const handleSave = async () => {
    if (validateForm()) {
      return toast.error("Formulario no válido");
    }
    const formData = new FormData();
    formData.append("username", currentUser.username);
    formData.append("password", currentUser.password);
    formData.append("email", currentUser.email);
    formData.append("role", "propietario");
    formData.append("cedula", currentUser.cedula); // Añadir la cédula al FormData
    formData.append("telefono", currentUser.telefono); // Añadir la cédula al FormData
    formData.append("foto", currentUser.foto); // Añadir la foto al FormData

    try {
      if (!currentUser.id) {
        await axiosInstance.post(`${baseUrl}/auth/register`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success("Usuario agregado exitosamente");
      } else {
        await axiosInstance.put(`${baseUrl}/auth/update/${currentUser.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success("Usuario editado exitosamente");
      }
      console.log("Usuario agregado exitosamente");

      fetchUsers();
      handleClose();
    } catch (error) {
      handleClose();
      toast.error(error.response.data.error);

      console.error("Error al agregar usuario:", error);
    }
  };

  const handleEdit = (user) => {
    setCurrentUser(user);
    setOpen(true);
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`${baseUrl}/auth/delete/${userToDelete.id}`);
      fetchUsers();
      setConfirmOpen(false);
      toast.success("Usuario eliminado exitosamente");
    } catch (error) {
      toast.error("Error al eliminar el usuario");
    }
  };

  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleNewUserChange = (e) => {
    const { name, value } = e.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };
  const handleFileChange = (e) => {
    setCurrentUser({ ...currentUser, foto: e.target.files[0] });
  };

  const fetchAreas = async () => {
    const response = await axiosInstance.get(`${baseUrl}/areas`);
    setAreas(response.data);
  };

  const fetchUserAreas = async (userId) => {
    const response = await axiosInstance.get(`${baseUrl}/areas/userareas/${userId}`);
    setUserAreas(response.data);
  };

  const handleManageAreasOpen = (user) => {
    setSelectedUser(user);
    fetchAreas();
    fetchUserAreas(user.id);
    setManageAreasOpen(true);
  };

  const handleManageAreasClose = () => {
    setManageAreasOpen(false);
  };

  const handlePermissionChange = async (areaId) => {
    try {
      const updatedUserArea = userAreas.find((ua) => ua.AreaId === areaId);
      if (updatedUserArea) {
        updatedUserArea.Allow = !updatedUserArea.Allow;
        await axiosInstance.put(
          `${baseUrl}/areas/userareas/${updatedUserArea.id}`,
          updatedUserArea
        );
      } else {
        await axiosInstance.post(`${baseUrl}/areas/userareas`, {
          UserId: selectedUser.id,
          AreaId: areaId,
          Allow: true,
        });
      }
      fetchUserAreas(selectedUser.id);
      toast.success("Permiso actualizado exitosamente");
    } catch (error) {
      toast.error("Error al actualizar el permiso");
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Función para solicitar el enlace de cambio de contraseña al backend
  const handleGeneratePasswordLink = async (userId) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(`${baseUrl}/auth/generatePasswordLink`, {
        userId, // Envía el ID del propietario al backend
      });
      setGeneratedLink(response.data.link); // Guarda el enlace en el estado
      setLinkOpen(true); // Abre el modal
    } catch (error) {
      console.error("Error al generar el enlace de cambio de contraseña:", error);
      toast.error("Error al generar el enlace de cambio de contraseña");
    } finally {
      setLoading(false);
    }
  };

  const filteredUsers = users.filter((user) =>
    user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ padding: 3 }}>
      <ToastContainer />
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Gestión de Propietarios</Typography>
        <TextField
          variant="outlined"
          placeholder="Buscar por nombre"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        {user && user.role === "admin" && (
          <Box>
            <Button variant="contained" color="primary" onClick={handleOpen}>
              Agregar Propietario
            </Button>
          </Box>
        )}
      </Box>
      <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "20px" }}>Nombre de Usuario</TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Correo Electrónico
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Cédula
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Telefono
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Rol
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {user.username}
                </TableCell>
                <TableCell align="right">{user.email}</TableCell>
                <TableCell align="right">{user.cedula}</TableCell>
                <TableCell align="right">{user.telefono}</TableCell>
                <TableCell align="right">{user.role}</TableCell>
                <TableCell align="right">
                  <IconButton color="primary" onClick={() => handleManageAreasOpen(user)}>
                    <ManageAccountsIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={() => handleEdit(user)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="warning" onClick={() => handleDeleteClick(user)}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleGeneratePasswordLink(user.id)}>
                    <ShareIcon /> {/* Icono para el enlace de cambio de contraseña */}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentUser.id ? "Editar Propietario" : "Agregar Propietario"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="username"
            label="Nombre de Usuario"
            type="text"
            fullWidth
            value={currentUser.username}
            onChange={handleNewUserChange}
            error={!!errors.username}
            helperText={errors.username}
          />
          <TextField
            margin="dense"
            name="password"
            label="Contraseña"
            type="password"
            fullWidth
            value={currentUser.password}
            onChange={handleNewUserChange}
            error={!!errors.password}
            helperText={errors.password}
          />
          <TextField
            margin="dense"
            name="email"
            label="Correo Electrónico"
            type="email"
            fullWidth
            value={currentUser.email}
            onChange={handleNewUserChange}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            margin="dense"
            name="cedula"
            label="Cédula"
            type="text"
            fullWidth
            value={currentUser.cedula}
            onChange={handleNewUserChange}
            error={!!errors.cedula}
            helperText={errors.cedula}
          />
          <TextField
            margin="dense"
            name="telefono"
            label="Telefono"
            type="text"
            fullWidth
            value={currentUser.telefono}
            onChange={handleNewUserChange}
            error={!!errors.telefono}
            helperText={errors.telefono}
          />

          <input
            type="file"
            name="foto"
            accept="image/*"
            onChange={handleFileChange} // Agregar esta línea para manejar el archivo
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmOpen} onClose={handleConfirmClose}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <Typography>¿Estás seguro de que quieres eliminar a {userToDelete?.username}?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={manageAreasOpen} onClose={handleManageAreasClose}>
        <DialogTitle>Gestionar Áreas para {selectedUser?.username}</DialogTitle>
        <DialogContent>
          {areas.map((area) => {
            const userArea = userAreas.find((ua) => ua.AreaId === area.id);
            return (
              <FormControlLabel
                key={area.id}
                control={
                  <Switch
                    checked={userArea?.Allow || false}
                    onChange={() => handlePermissionChange(area.id)}
                    color="primary"
                  />
                }
                label={area.nombre}
              />
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleManageAreasClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleManageAreasClose} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={linkOpen} onClose={() => setLinkOpen(false)}>
        <DialogTitle>Enlace de Cambio de Contraseña</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Aquí está el enlace generado para el cambio de contraseña:
          </Typography>
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            value={generatedLink}
            InputProps={{ readOnly: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(generatedLink);
              toast.success("Enlace copiado al portapapeles");
            }}
            color="primary"
            startIcon={<CopyIcon />}
          >
            Copiar Enlace
          </Button>
          <Button
            onClick={() => window.open(generatedLink, "_blank")}
            color="primary"
            startIcon={<ShareIcon />}
          >
            Compartir Enlace
          </Button>
          <Button onClick={() => setLinkOpen(false)} color="secondary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Propietario;
