import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Button,
  Typography,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  Input,
  MenuItem,
} from "@mui/material";

import { format } from "date-fns";
const baseUrl = process.env.REACT_APP_BASE_URL;

const InvitadosPorPropietario = () => {
  const [invitados, setInvitados] = useState([]);
  const [lotes, setLotes] = useState([]);
  const [entradas, setEntradas] = useState([]);
  //   const [propietarios, setPropietarios] = useState([]);
  const [selectedPropietario, setSelectedPropietario] = useState("");
  const [open, setOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState({ id: "", lote: "", action: "" });

  useEffect(() => {
    fetchInvitados();
    fetchLotes();
    // fetchPropietarios();
    fetchEntradas();
  }, []);

  const fetchInvitados = async () => {
    const response = await axiosInstance.get(`${baseUrl}/invitados/invitadosPortero/getAll`);
    setInvitados(response.data);
  };

  const fetchLotes = async () => {
    const response = await axiosInstance.get(`${baseUrl}/lotes`);
    setLotes(response.data);
  };

  //   const fetchPropietarios = async () => {
  //     const response = await axiosInstance.get(`${baseUrl}/propietarios`);
  //     setPropietarios(response.data);
  //   };

  const fetchEntradas = async () => {
    const response = await axiosInstance.get(`${baseUrl}/lotes/entradas/propietario`);
    setEntradas(response.data);
  };

  const handleOpenEntry = () => {
    setCurrentAction({ id: "", lote: "", action: "" });
    setOpen(true);
  };

  const handleCloseEntry = () => {
    setOpen(false);
  };

  const handleSaveEntry = async () => {
    if (currentAction.id && currentAction.lote && currentAction.action) {
      await axiosInstance.post(`${baseUrl}/invitados/entry`, currentAction);
      fetchEntradas();
      handleCloseEntry();
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Logic for displaying the correct rows per page
  const paginatedEntradas = entradas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ padding: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Historial de Invitados</Typography>
        <Button variant="contained" color="primary" onClick={handleOpenEntry}>
          Agregar Entrada
        </Button>
      </Box>
      {/* <FormControl fullWidth margin="dense" mb={3}>
        <InputLabel id="select-propietario-label">Seleccionar Propietario</InputLabel>
        <Select
          labelId="select-propietario-label"
          value={selectedPropietario}
          onChange={handlePropietarioChange}
          input={<Input />}
        >
          {propietarios.map((propietario) => (
            <MenuItem key={propietario.id} value={propietario.id}>
              {propietario.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
      <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Invitado
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Lote
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Tipo
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Fecha
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedEntradas.map((entrada) => (
              <TableRow key={entrada.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {entrada.nombre}
                </TableCell>
                <TableCell align="left">
                  Lote : {entrada.lote} Manzana : {entrada.manzana}
                </TableCell>
                <TableCell align="left">{entrada.action}</TableCell>
                <TableCell align="left">
                  {format(new Date(entrada.createdAt), "dd/MM/yyyy HH:mm:ss")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={entradas.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          labelRowsPerPage="Mostrar"
        />
      </TableContainer>

      <Dialog open={open} onClose={handleCloseEntry}>
        <DialogTitle>Agregar Entrada</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <InputLabel id="share-user-select-label">Seleccionar Usuario</InputLabel>
            <Select
              labelId="share-user-select-label"
              value={currentAction.id}
              onChange={(e) => setCurrentAction({ ...currentAction, id: e.target.value })}
              input={<Input />}
            >
              {invitados.map((invitado) => (
                <MenuItem key={invitado.id} value={invitado.id}>
                  {invitado.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="share-lote-select-label">Seleccionar Lote</InputLabel>
            <Select
              labelId="share-lote-select-label"
              value={currentAction.lote}
              onChange={(e) => setCurrentAction({ ...currentAction, lote: e.target.value })}
              input={<Input />}
            >
              {lotes.map((lote) => (
                <MenuItem key={lote.id} value={lote.id}>
                  Lote : {lote.lote} - Manzana : {lote.manzana}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="share-action-select-label">Seleccionar Acción</InputLabel>
            <Select
              labelId="share-action-select-label"
              value={currentAction.action}
              onChange={(e) => setCurrentAction({ ...currentAction, action: e.target.value })}
              input={<Input />}
            >
              <MenuItem value="Entrada">Entrada</MenuItem>
              <MenuItem value="Salida">Salida</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEntry} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSaveEntry} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InvitadosPorPropietario;
