import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import {
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Modal,
  TextField,
  Grid,
  Box,
  Divider,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import esLocale from "date-fns/locale/es";

import dayjs from "dayjs";
const baseUrl = process.env.REACT_APP_BASE_URL;

const HistorialMantenimiento = () => {
  const { id } = useParams(); // Obtener el id del equipo desde los parámetros de la URL
  const [historial, setHistorial] = useState([]);
  const [equipo, setEquipo] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null); // Registro seleccionado para ver detalles
  const [nuevoRegistro, setNuevoRegistro] = useState({
    fechaProximo: null,
    descripcion: "",
    id: id,
  });

  useEffect(() => {
    fetchHistorial();
  }, [id]);

  const fetchHistorial = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/equipos/historial/${id}`);
      const data = await response.data;

      setHistorial(data.historial);
      setEquipo(data.equipo);
    } catch (error) {
      console.error("Error al cargar el historial de mantenimiento:", error);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showDetailModal = (record) => {
    setSelectedRecord(record);
    setIsDetailModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/equipos/historial/${id}`, {
        ...nuevoRegistro,
      });
      if (response.status === 201) {
        fetchHistorial();
      }
    } catch (error) {
      console.error("Error al agregar registro de mantenimiento:", error);
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDetailModalClose = () => {
    setIsDetailModalVisible(false);
    setSelectedRecord(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNuevoRegistro((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    const today = dayjs().startOf("day"); // Fecha actual sin horas ni minutos

    if (date && date.isAfter(today)) {
      setNuevoRegistro((prev) => ({
        ...prev,
        fechaProximo: date,
      }));
    } else {
      alert("La fecha de mantenimiento no puede ser menor o igual a la fecha actual.");
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom style={{ textAlign: "center", marginBottom: 20 }}>
        Historial de Mantenimiento de {equipo && equipo.nombre} - {equipo && equipo.serie}
      </Typography>
      <Box display="flex" justifyContent="center" mb={3}>
        <Button variant="contained" color="primary" onClick={showModal}>
          Agregar Registro
        </Button>
      </Box>

      <Grid container spacing={3} style={{ padding: "0 20px" }}>
        {historial.map((record) => (
          <Grid item xs={12} sm={6} md={4} key={record.id}>
            <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
              <CardContent>
                <Typography variant="h6" color="textSecondary">
                  Fecha: {new Date(record.fecha).toLocaleDateString()}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    marginTop: "10px",
                    maxHeight: "80px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {record.descripcion.length > 100
                    ? `${record.descripcion.substring(0, 100)}...`
                    : record.descripcion}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary" onClick={() => showDetailModal(record)}>
                  Ver Detalles
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Modal open={isModalVisible} onClose={handleCancel}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Agregar Registro de Mantenimiento
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs} locale={esLocale}>
            <DatePicker
              label="Proxima fecha de mantenimiento"
              value={nuevoRegistro.fechaProximo}
              onChange={handleDateChange}
              minDate={dayjs().startOf("day")}
              sx={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
          <TextField
            label="Descripción"
            name="descripcion"
            fullWidth
            multiline
            rows={3}
            value={nuevoRegistro.descripcion}
            onChange={handleInputChange}
            margin="normal"
          />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={handleCancel} color="inherit" sx={{ mr: 1 }}>
              Cancelar
            </Button>
            <Button variant="contained" color="primary" onClick={handleOk}>
              Guardar
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={isDetailModalVisible} onClose={handleDetailModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h5" gutterBottom>
            Detalles del Registro
          </Typography>
          {selectedRecord && (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle1" color="textSecondary">
                Fecha:
              </Typography>
              <Typography variant="body1" gutterBottom>
                {new Date(selectedRecord.fecha).toLocaleDateString()}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Descripción:
              </Typography>
              <Typography variant="body1" paragraph>
                {selectedRecord.descripcion}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Box display="flex" justifyContent="flex-end">
                <Button onClick={handleDetailModalClose} color="primary" variant="outlined">
                  Cerrar
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default HistorialMantenimiento;
