import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../../axiosInstance";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  Button,
  TextField, // Importar TextField
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import InfoIcon from "@mui/icons-material/Info"; // Importar el icono de información
import UserContext from "../../UserContext";
import { toast } from "react-toastify";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Propietarios = () => {
  const { setUser, user } = useContext(UserContext);
  const [propietarios, setPropietarios] = useState([]);
  const [users, setUsers] = useState([]);
  const [areas, setAreas] = useState([]);
  const [userAreas, setUserAreas] = useState([]);
  const [manageAreasOpen, setManageAreasOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // Estado para el término de búsqueda

  const navigate = useNavigate();

  const handleRedirect = (prop) => {
    navigate(`/invitadosByProps/${prop}`);
  };

  const handleDetailsRedirect = (prop) => {
    navigate(`/propietarios/${prop}`);
  };

  useEffect(() => {
    fetchPropietarios();
    fetchUsers();
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Logic for displaying the correct rows per page
  const paginatedPropietarios = propietarios
    .filter((propietario) => propietario.username.toLowerCase().includes(searchTerm.toLowerCase()))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    // Intenta obtener el usuario desde localStorage al cargar la aplicación
    const storedUser = localStorage.getItem("user");
    if (storedUser !== null && typeof storedUser !== "object") {
      setUser(JSON.parse(storedUser)); // Parsea el usuario almacenado en localStorage si no es un objeto
    }
  }, [setUser]);

  const fetchPropietarios = async () => {
    const response = await axiosInstance.get(`${baseUrl}/auth/props`);
    setPropietarios(response.data);
  };

  const fetchUsers = async () => {
    const response = await axiosInstance.get(`${baseUrl}/auth`);
    setUsers(response.data);
  };

  const fetchAreas = async () => {
    const response = await axiosInstance.get(`${baseUrl}/areas`);
    setAreas(response.data);
  };

  const fetchUserAreas = async (userId) => {
    const response = await axiosInstance.get(`${baseUrl}/areas/userareas/${userId}`);
    setUserAreas(response.data);
  };

  const handleManageAreasOpen = (user) => {
    setSelectedUser(user);
    fetchAreas();
    fetchUserAreas(user.id);
    setManageAreasOpen(true);
  };

  const handleManageAreasClose = () => {
    setManageAreasOpen(false);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Listado de propietarios</Typography>
        <TextField
          label="Buscar por nombre"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>
      <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "20px" }}>Propietario</TableCell>
              <TableCell sx={{ fontSize: "20px" }}>Lote</TableCell>
              <TableCell sx={{ fontSize: "20px" }}>Manzana</TableCell>
              <TableCell sx={{ fontSize: "20px" }}>Invitados</TableCell>
              <TableCell sx={{ fontSize: "20px" }}>Permisos</TableCell>
              <TableCell sx={{ fontSize: "20px" }}>Detalles</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedPropietarios.map((propietario) => (
              <TableRow
                key={propietario.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {propietario.username}
                </TableCell>
                <TableCell component="th" scope="row">
                  {propietario.lote}
                </TableCell>
                <TableCell component="th" scope="row">
                  {propietario.manzana}
                </TableCell>
                <TableCell component="th" scope="row">
                  <IconButton color="primary" onClick={() => handleRedirect(propietario.prop)}>
                    <ArrowForwardIcon />
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  <IconButton color="primary" onClick={() => handleManageAreasOpen(propietario)}>
                    <ManageAccountsIcon />
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  <IconButton
                    color="primary"
                    onClick={() => handleDetailsRedirect(propietario.prop)}
                  >
                    <InfoIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={propietarios.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          labelRowsPerPage="Mostrar"
        />
      </TableContainer>

      <Dialog open={manageAreasOpen} onClose={handleManageAreasClose}>
        <DialogTitle>Gestionar Áreas para {selectedUser?.username}</DialogTitle>
        <DialogContent>
          <List>
            {areas.map((area) => {
              const userArea = userAreas.find((ua) => ua.AreaId === area.id);
              return (
                <ListItem key={area.id}>
                  <Typography variant="body1" sx={{ flexGrow: 1 }}>
                    {area.nombre}
                  </Typography>
                  {userArea?.Allow ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleManageAreasClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Propietarios;
