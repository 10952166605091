import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
} from "@mui/material";
import axiosInstance from "../../axiosInstance";
import QRCode from "qrcode.react"; // Importar la librería para generar QR
import { useNavigate } from "react-router-dom";
const baseUrl = process.env.REACT_APP_BASE_URL;

const GenerarQR = () => {
  const [monto, setMonto] = useState("");
  const [qrData, setQrData] = useState("");
  const [lotes, setLotes] = useState([]);
  const [lote, setLote] = useState("");
  const [voucher, setVoucher] = useState("");
  const [qrUsed, setQrUsed] = useState(false);

  const navigate = useNavigate();
  const handleRedirectToHistorial = () => {
    navigate("/propietario/voucher/historial");
  };
  useEffect(() => {
    fetchLotes();
    fetchVoucher();
  }, []);

  const handleGenerateQR = async () => {
    if (lote && monto) {
      try {
        // Crear los datos del QR en formato JSON
        // const qrPayload = JSON.stringify({ LoteId: lote, monto });

        // Verificar en el backend si ya se generó un QR para este lote
        const response = await axiosInstance.post(`${baseUrl}/auth/check-qr`, { loteId: lote });
        if (response.data.qrUsed) {
          setQrUsed(true);
          alert("Este QR ya ha sido utilizado.");
          return;
        }
        const qrPayload = JSON.stringify({ tokenQR: response.data.tokenQR, monto });
        setQrData(qrPayload);
      } catch (error) {
        console.error("Error al generar QR:", error);
      }
    } else {
      alert("Por favor, ingresa un ID de propietario y un monto.");
    }
  };

  const fetchLotes = async () => {
    const response = await axiosInstance.get(`${baseUrl}/lotes/getMyLotes`);
    setLotes(response.data);
  };

  const fetchVoucher = async () => {
    const response = await axiosInstance.get(`${baseUrl}/auth/get-voucher`);
    setVoucher(response.data);
  };

  return (
    <Box
      sx={{
        padding: 3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={handleRedirectToHistorial}
        fullWidth
        sx={{
          alignSelf: "center",
          maxWidth: 500,
          padding: "12px 16px",
          backgroundColor: "#1976d2",
          ":hover": {
            backgroundColor: "#1565c0",
          },
          fontSize: "1.1rem",
        }}
      >
        Historial de Vouchers
      </Button>
      <Card
        sx={{
          maxWidth: 500,
          mx: "auto",
          my: 5,
          p: 3,
          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
          borderRadius: 2,
        }}
      >
        <CardContent>
          <Typography variant="h4" gutterBottom sx={{ textAlign: "center", fontWeight: "bold" }}>
            Generar QR para Cobro
          </Typography>

          <Box mt={2} mb={3}>
            {voucher &&
              voucher.map((voucherItem) => (
                <Typography
                  key={voucherItem.id}
                  variant="h6"
                  sx={{
                    backgroundColor: "#f5f5f5",
                    padding: 2,
                    borderRadius: 1,
                    marginBottom: 2,
                  }}
                >
                  <strong>Lote:</strong> {voucherItem.lote} - <strong>Manzana:</strong>{" "}
                  {voucherItem.manzana} - <strong>Saldo:</strong>{" "}
                  {voucherItem.Voucher ? voucherItem.Voucher.saldo : "0"} Gs
                </Typography>
              ))}
          </Box>

          <Box display="flex" flexDirection="column" gap={3}>
            <FormControl fullWidth margin="dense">
              <InputLabel id="lote-select-label">Seleccionar Lote</InputLabel>
              <Select
                labelId="lote-select-label"
                value={lote}
                onChange={(e) => setLote(e.target.value)}
                input={<Input />}
              >
                {lotes.map((loteItem) => (
                  <MenuItem key={loteItem.id} value={loteItem.id}>
                    Lote: {loteItem.lote} - Manzana: {loteItem.manzana}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Monto"
              type="number"
              variant="outlined"
              value={monto}
              onChange={(e) => setMonto(e.target.value)}
              fullWidth
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerateQR}
              fullWidth
              sx={{
                padding: "12px 16px",
                backgroundColor: "#1976d2",
                ":hover": {
                  backgroundColor: "#1565c0",
                },
                fontSize: "1.1rem",
              }}
            >
              Generar QR
            </Button>
          </Box>

          {qrData && (
            <Box
              mt={4}
              textAlign="center"
              sx={{
                padding: 3,
                border: "1px solid #e0e0e0",
                borderRadius: 2,
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
                mt: 3,
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                Escanea el código QR:
              </Typography>
              <QRCode value={qrData} size={256} level="H" includeMargin={true} />
              {qrUsed && (
                <Typography color="error" sx={{ mt: 2 }}>
                  Este QR ya ha sido utilizado.
                </Typography>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default GenerarQR;
