import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import axiosInstance from "../../axiosInstance";
import { useNavigate } from "react-router-dom";
const baseUrl = process.env.REACT_APP_BASE_URL;
const PanelControl = () => {
  const navigate = useNavigate();
  const [lotesCount, setLotesCount] = useState(0);
  const [ownersCount, setOwnersCount] = useState(0);
  const [employeesCount, setEmployeesCount] = useState(0);
  const [incomeExpenseData, setIncomeExpenseData] = useState([]);

  useEffect(() => {
    // Fetch data from your API to populate the counts and ingresos/egresos data
    fetchCounts();
    fetchIncomeExpenseData();
  }, []);

  const fetchCounts = async () => {
    // Simula la llamada a la API
    const lotes = await axiosInstance.get(`${baseUrl}/lotes`);
    const propietarios = await axiosInstance.get(`${baseUrl}/auth/getAllPropietarios`);
    const personal = await axiosInstance.get(`${baseUrl}/auth/getAllMantenimiento`);
    setLotesCount(lotes.data.length); // Replace with API call
    setOwnersCount(propietarios.data.length); // Replace with API call
    setEmployeesCount(personal.data.length); // Replace with API call
  };

  const fetchIncomeExpenseData = async () => {
    // Simula la llamada a la API para los datos del gráfico
    setIncomeExpenseData([
      { name: "Enero", ingresos: 4000, egresos: 2400 },
      { name: "Febrero", ingresos: 3000, egresos: 1398 },
      { name: "Marzo", ingresos: 2000, egresos: 9800 },
      // more data...
    ]);
  };

  return (
    <Grid padding={3} container spacing={3}>
      <Grid sx={{ cursor: "pointer" }} item xs={12} sm={4} onClick={() => navigate("/lotes")}>
        <Paper sx={{ padding: 3 }} elevation={3} className="PanelControl-card">
          <Typography variant="h6">Cantidad de Lotes</Typography>
          <Typography variant="h4">{lotesCount}</Typography>
        </Paper>
      </Grid>
      <Grid
        sx={{ cursor: "pointer" }}
        item
        xs={12}
        sm={4}
        onClick={() => navigate("/propietariosAdmin")}
      >
        <Paper sx={{ padding: 3 }} elevation={3} className="PanelControl-card">
          <Typography variant="h6">Propietarios y Familiares</Typography>
          <Typography variant="h4">{ownersCount}</Typography>
        </Paper>
      </Grid>
      <Grid sx={{ cursor: "pointer" }} item xs={12} sm={4} onClick={() => navigate("/personal")}>
        <Paper sx={{ padding: 3 }} elevation={3} className="PanelControl-card">
          <Typography variant="h6">Empleados Activos</Typography>
          <Typography variant="h4">{employeesCount}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={3} className="PanelControl-card">
          <Typography padding={3} variant="h6">
            Ingresos y Egresos
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={incomeExpenseData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="ingresos" fill="#82ca9d" />
              <Bar dataKey="egresos" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PanelControl;
