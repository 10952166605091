import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import { toast } from "react-toastify";

const ValidateEntry = () => {
  const { token, lote, invitacionToken } = useParams();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  console.log(token, lote);

  useEffect(() => {
    const validate = async () => {
      try {
        // Llama al controlador o API para validar la entrada
        await axiosInstance.post(`${baseUrl}/invitados/validateEntry`, {
          token,
          lote,
          invitacionToken,
        });
        // Redirige después de la validación exitosa
        navigate("/invitadosTotal", { replace: true });
      } catch (error) {
        console.error("Error during validation:", error);
        toast.error(error.response.data.error);
        navigate("/error", { replace: true, state: { error: error.response.data.error } });
      }
    };

    validate();
  }, [token, lote, navigate]); // Dependencias que deben activar el efecto

  return <div>Validando entrada...</div>; // Mensaje mientras se valida
};

export default ValidateEntry;
