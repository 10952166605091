import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance.js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable";

const baseUrl = process.env.REACT_APP_BASE_URL;

const UserHistorialVoucher = () => {
  const [vouchers, setVouchers] = useState([]);

  useEffect(() => {
    // if (userId) {
    fetchUserVouchers();
    // }
  }, []);

  const fetchUserVouchers = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/auth/get-historial-vouchers-by-userId`);
      setVouchers(response.data);
    } catch (error) {
      console.error("Error fetching user vouchers:", error);
    }
  };

  const downloadPdf = () => {
    const doc = new jsPDF();
    const tableColumn = ["Saldo", "Fecha", "Lote", "Manzana", "Propietarios"];
    const tableRows = [];

    vouchers.forEach((voucher) => {
      const propietarios = voucher.propietarios
        .map((prop) => `${prop.username} (ID: ${prop.id})`)
        .join(", ");
      const voucherData = [
        voucher.saldo,
        new Date(voucher.fecha).toLocaleDateString(),
        voucher.lote,
        voucher.manzana,
        propietarios,
      ];
      tableRows.push(voucherData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });

    doc.save("historial_vouchers_usuario.pdf");
  };

  return (
    <Box sx={{ padding: 3 }}>
      <ToastContainer />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Historial de Vouchers del Usuario</Typography>
        <Button variant="contained" color="primary" onClick={downloadPdf}>
          Descargar PDF
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "20px" }}>Saldo</TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Fecha
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Lote
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Manzana
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Propietario
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vouchers.map((voucher) => (
              <TableRow key={voucher.id}>
                <TableCell component="th" scope="row">
                  {voucher.saldo}
                </TableCell>
                <TableCell align="right">{new Date(voucher.fecha).toLocaleDateString()}</TableCell>
                <TableCell align="right">{voucher.lote}</TableCell>
                <TableCell align="right">{voucher.manzana}</TableCell>
                <TableCell align="right">
                  {voucher.propietarios.map((prop) => (
                    <Typography key={prop.id}>{prop.username}</Typography>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UserHistorialVoucher;
