import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
const baseUrl = process.env.REACT_APP_BASE_URL;

const InvitadosPropietarios = () => {
  const [invitados, setInvitados] = useState([]);
  const [cliente, setCliente] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchInvitados();
    fetchCliente();
  }, []);

  const fetchInvitados = async () => {
    const response = await axiosInstance.get(`${baseUrl}/invitados/invitadosByProp/${id}`);
    setInvitados(response.data);
  };

  const fetchCliente = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/auth/prop/${id}`);
      if (response.data) {
        setCliente(response.data);
      } else {
        navigate("/not-authorized");
      }
    } catch (error) {
      console.error("Error fetching cliente:", error);
      navigate("/not-authorized");
    }
  };

  if (!cliente) {
    return null; // O muestra un spinner o mensaje de carga si prefieres
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">
          Listado de invitados del propietario {cliente.username}
        </Typography>
      </Box>
      <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "20px" }}>Nombre</TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Cédula de Identidad
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Género
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Edad
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Email
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invitados.map((invitado) => (
              <TableRow
                key={invitado.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {invitado.nombre}
                </TableCell>
                <TableCell align="right">{invitado.cedula_identidad}</TableCell>
                <TableCell align="right">{invitado.genero}</TableCell>
                <TableCell align="right">{invitado.edad}</TableCell>
                <TableCell align="right">{invitado.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default InvitadosPropietarios;
